import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"
import gdpr_img from "../images/gdpr-img.jpg"

const GovernancePolicyDevelopment = () => {
    return (
        <div>
            <Header  pageTitle="Policy Development"/>
            <section className="py-5 px-md-5 mx-xl-5">
                <Container>
                    <h2 className="text-theme">Governance Policy Development</h2>
                    <Row>
                        <Col md="12">
                            <img src={gdpr_img} alt="GDPR" className="img-fluid" />
                            <p>For organizations that require Governance policy development,
                            ESG develops industry specific policy libraries tailored for
                            each organization to satisfy regulatory compliance as well as
                                educate corporate leadership on their responsibilities</p>
                        </Col>
                        <Col md="12">
                            <h5 className="my-3">Compliance Packages</h5>
                        </Col>
                        <Col md="6">
                            <ul>
                                <li><i className="fa fa-chevron-right"></i> PCI-DSS</li>
                                <li><i className="fa fa-chevron-right"></i> GDPR</li>
                                <li><i className="fa fa-chevron-right"></i> HIPPA</li>
                                <li><i className="fa fa-chevron-right"></i> CJIJS</li>
                            </ul>
                        </Col>
                        <Col md="6">
                            <ul>
                                <li><i className="fa fa-chevron-right"></i> NIST</li>
                                <li><i className="fa fa-chevron-right"></i> CMMS</li>
                                <li><i className="fa fa-chevron-right"></i> HITRUST</li>
                                <li><i className="fa fa-chevron-right"></i> CMS MARS-E</li>
                            </ul>
                        </Col>
                        <Col md="12">
                            <h5 className="my-3">Customized Policy Packages – Tailored to fit your unique requirements</h5>
                        </Col>
                        <Col md="6">
                            <ul>
                                <li><i className="fa fa-chevron-right"></i> Disaster Recovery and Continuity of Operations Plan (DR/COOP)</li>
                                <li><i className="fa fa-chevron-right"></i> Incident Response Plan</li>
                                <li><i className="fa fa-chevron-right"></i> Change and Configuration Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Security Education and Awareness Policy and Plan</li>
                                <li><i className="fa fa-chevron-right"></i> Acceptable Use Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Backup Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Privacy Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Data Classification Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Email Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Encryption Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Guest Access Policy</li>
                            </ul>
                        </Col>
                        <Col md="6">
                            <ul>
                                <li><i className="fa fa-chevron-right"></i> Mobile Device Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Network Access and Authentication Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Network Security Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Outsourcing Business Assoc. Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Password Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Physical Security Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Data Retention Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Third Party Connection Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Remote Access Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Wireless Access Policy</li>
                                <li><i className="fa fa-chevron-right"></i> Incident Response Policy</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
export default GovernancePolicyDevelopment